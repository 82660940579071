<template>
  <v-card class="cards mt-5">
    <v-card-title v-if="title" class="card-h-title pb-0 pt-5" >{{ title }}</v-card-title>
    <div class="text-center py-4 px-3">
      <h6 style="line-height: 28px">
        <v-icon class="primary--text me-1">mdi-monitor</v-icon>
        برای مشاهده این قسمت از نسخه دسکتاپ استفاده نمایید.
      </h6>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["title"],
};
</script>
